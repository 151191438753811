html, body {
  height: 100vh;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  color: #111;
  background: #e3e3e3 radial-gradient(circle at 90% 90%, #999, #9b9b9b, #9f9f9f, #a6a6a6, #afafaf, #b8b8b8, #c2c2c2, #ccc, #d5d5d5, #dcdcdc, #e1e1e1, #e3e3e3);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  display: flex;
}

@media (min-width: 420px) {
  body {
    flex-direction: row;
  }

  body header {
    border-right: .125rem solid #6666;
    align-items: flex-end;
    padding-right: 1rem;
  }

  body header h1 {
    margin-top: 0;
    margin-bottom: .5rem;
  }

  body header p {
    margin-top: .5rem;
    margin-bottom: 0;
  }
}

::selection {
  background: #1111111a;
}

header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

h1 {
  letter-spacing: -1px;
  text-align: center;
  margin: 1rem;
  font-size: 3rem;
  font-weight: 100;
  line-height: 3rem;
}

p {
  text-align: center;
  margin: 1rem;
  font-size: 1.125rem;
  font-weight: 200;
}

footer ul {
  flex-direction: row;
  margin: 1rem;
  padding: 0;
  list-style-type: none;
  display: flex;
}

footer li {
  margin: 0 .5rem;
  padding: 0;
}

footer li a {
  opacity: .666;
  padding: .5rem;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  display: block;
}

footer li a:hover, footer li a:active {
  opacity: .8;
  transform: scale(1.2);
}

footer li a img {
  display: block;
}

@media (min-width: 1024px) {
  footer li a img {
    width: 32px;
    height: 32px;
  }
}

.spin {
  transition: all .8s ease-in;
}

.spin:hover {
  transform: rotate(360deg);
}

/*# sourceMappingURL=index.feb7c5e6.css.map */
